import {
  ExpertCardItemsType,
  SocialLinksItemsType,
  TagInterface,
  LogoLandingInterface,
  ImageFirstFoldInterface,
  LeapNewsItemType,
  AccordionData,
  AboutUsBioCard,
} from "components/LSRevamp/LSRevampInterfaces"

export const leapNewsItems: LeapNewsItemType[] = [
  {
    backgroundColor: "bg-[#F8E5E5]",
    logo: `${process.env.CDN_URL}/assets/icons/LSRevamp/tech-in-asia-logo-vector7.svg`,
    content: `"Jungle Ventures invests in Leap to tap into Asia’s ed-tech market"`,
    width: 220,
    height: 48,
    mobileSizes: {
      width: 133,
      height: 30,
    },
  },
  {
    backgroundColor: "bg-[#FAEFE0]",
    logo: `${process.env.CDN_URL}/assets/icons/LSRevamp/livemint-logo-vector9.svg`,
    content: `"Sequoia India joins 126 crores round in overseas education startup Leap"`,
    width: 175,
    height: 42,
    mobileSizes: {
      width: 123,
      height: 30,
    },
  },
  {
    backgroundColor: "bg-[#E9F7E2]",
    logo: `${process.env.CDN_URL}/assets/icons/LSRevamp/techcrunch-logo.svg`,
    content: `"Leap raises $55 million to help Indian students study abroad"`,
    width: 233,
    height: 37,
    mobileSizes: {
      width: 173,
      height: 26,
    },
  },
  {
    backgroundColor: "bg-[#F8E5E5]",
    logo: `${process.env.CDN_URL}/assets/icons/LSRevamp/4th-of-their-business-news-reading-time-on-economictimes-com4.svg`,
    content: `"Leap raises 40 crores led by Sequoia India”`,
    width: 70,
    height: 50,
    mobileSizes: {
      width: 56,
      height: 42,
    },
  },
  {
    backgroundColor: "bg-[#F8E5E5]",
    logo: `${process.env.CDN_URL}/assets/icons/LSRevamp/Yourstory5.svg`,
    content: `"Ed-tech platform Leap Finance raises 126 crores in series B round led by Jungle Ventures"`,
    width: 85,
    height: 38,
    mobileSizes: {
      width: 77,
      height: 25,
    },
  },
]

export const socialLinksItems: SocialLinksItemsType[] = [
  {
    logo: {
      path: `${process.env.CDN_URL}/assets/icons/LSRevamp/google_playstore.svg`,
      width: 135,
      height: 40,
    },
    count: "1M",
    countType: "Downloads",
    backgroundColorClass: "bg-[#F5F4EA]",
  },
  {
    logo: {
      path: `${process.env.CDN_URL}/assets/icons/LSRevamp/youtube.svg`,
      width: 51,
      height: 36,
    },
    count: "100K",
    countType: "Subscribers",
    secondLogo: {
      path: `${process.env.CDN_URL}/assets/icons/LSRevamp/girl_vector.svg`,
      width: 62,
      height: 96,
    },
    backgroundColorClass: "bg-[#FFF8F8]",
  },
  {
    logo: {
      path: `${process.env.CDN_URL}/assets/icons/LSRevamp/instagram.svg`,
      width: 43,
      height: 41,
    },
    count: "200K",
    countType: "Instagram Family",
    backgroundColorClass: "bg-[#F2F5F5]",
  },
  {
    count: "100K",
    countType: "Community Members",
    secondLogo: {
      path: `${process.env.CDN_URL}/assets/icons/LSRevamp/community.svg`,
      width: 85,
      height: 70,
    },
    backgroundColorClass: "bg-[#EFEDFF]",
  },
]

export const expertCardItems: ExpertCardItemsType[] = [
  {
    image: `${process.env.CDN_URL}/assets/icons/LSRevamp/Outdoor_Pic.svg`,
    linkedinAddress: "#",
    personName: "Priya Trivedi",
    numberOfStudents: 1000,
    yearsOfExperience: 5,
    expertise: ["Counselling", "IELTS", "SOP"],
    content: "Experience in IELTS/ PTE and OET, MSc & BEd degree holder",
    withExpertise: true,
  },
  {
    image: `${process.env.CDN_URL}/assets/icons/LSRevamp/Girl2.svg`,
    linkedinAddress: "#",
    personName: "Priya Trivedi 2",
    numberOfStudents: 1000,
    yearsOfExperience: 5,
    expertise: ["Counselling", "IELTS", "SOP"],
    content: "Experience in IELTS/ PTE and OET, MSc & BEd degree holder",
    withExpertise: false,
    gradientColors: {
      from: "from-[#FFF3E0]",
      to: "to-[#C3C9FE]",
    },
  },
  {
    image: `${process.env.CDN_URL}/assets/icons/LSRevamp/Outdoor_Pic.svg`,
    linkedinAddress: "#",
    personName: "Priya Trivedi",
    numberOfStudents: 1000,
    yearsOfExperience: 5,
    expertise: ["Counselling", "IELTS", "SOP"],
    content: "Experience in IELTS/ PTE and OET, MSc & BEd degree holder",
    withExpertise: true,
  },
  {
    image: `${process.env.CDN_URL}/assets/icons/LSRevamp/Girl2.svg`,
    linkedinAddress: "#",
    personName: "Priya Trivedi 2",
    numberOfStudents: 1000,
    yearsOfExperience: 5,
    expertise: ["Counselling", "IELTS", "SOP"],
    content: "Experience in IELTS/ PTE and OET, MSc & BEd degree holder",
    withExpertise: false,
    gradientColors: {
      from: "from-[#FFF3E0]",
      to: "to-[#C3C9FE]",
    },
  },
]

export const stickyTagData: TagInterface[] = [
  { text: "Press Release", bgColor: "#E2F9DF", textColor: "#3C9860" },
  { text: "Ed-Tech", bgColor: "#DBE6FF", textColor: "#5940F0" },
]

export const imageLogoData: LogoLandingInterface[] = [
  {
    url: `${process.env.CDN_URL}/assets/images/LSRevamp/global-investors/apispartners.png`,
    alt: "logo-1",
    height: "110px",
    mobHeight: "45px",
    width: "170px",
    mobWidth: "67px",
  },
  {
    url: `${process.env.CDN_URL}/assets/images/LSRevamp/global-investors/peak-xv-partners.png`,
    alt: "logo-4",
    height: "60px",
    width: "148px",
    mobHeight: "30px",
    mobWidth: "75px",
  },
  {
    url: `${process.env.CDN_URL}/assets/images/LSRevamp/global-investors/owl-ventures.png`,
    alt: "logo-3",
    height: "48px",
    width: "127px",
    mobHeight: "22px",
    mobWidth: "57px",
  },
  {
    url: `${process.env.CDN_URL}/assets/images/LSRevamp/global-investors/jungle-ventures.png`,
    alt: "logo-2",
    height: "74px",
    width: "163px",
    mobHeight: "33px",
    mobWidth: "73px",
  },
  
]

export const IMG_URLS_DESKTOP: ImageFirstFoldInterface[] = [
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-des-1_XEfHtYtTa.png",
    alt: "carousel-img-1",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-des-2_tCEs_yJN5.png",
    alt: "carousel-img-2",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-des-4_HOaprouqM.png",
    alt: "carousel-img-4",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-des-5_gUNKvso16.png",
    alt: "carousel-img-5",
  },
]

export const IMG_URLS_MOBILE: ImageFirstFoldInterface[] = [
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-mob-1_1HWzrvbh9.png",
    alt: "carousel-img-1",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-mob-2_Yh-eeUbXY.png",
    alt: "carousel-img-2",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-mob-4_b74re6_tbH.png",
    alt: "carousel-img-4",
  },
  {
    url: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/hero-landing-mob-5_6cWL_3opAp.png",
    alt: "carousel-img-5",
  },
]
export interface CardWithImageItemsType {
  image: string
  linkedinAddress: string
  personName: string
  numberOfStudents?: number
  yearsOfExperience: number
  expertise?: string[]
  content?: string
  gradientColors?: any
  withExpertise: boolean
  isMobileSized?: boolean
}

export interface CounsellorObj {
  title: string
  description: string
  image: string
}

export const statsObj: CounsellorObj[] = [
  {
    title: "Comprehensive Knowledge Base",
    description:
      "Leap training modules ensure counsellors are up to date with latest development",
    image: `${process.env.CDN_URL}/assets/images/LSRevamp/knowledge.svg`,
  },
  {
    title: "Profile-based allocation",
    description:
      "Counsellors are allocated based on your profile to serve you better",
    image: `${process.env.CDN_URL}/assets/images/LSRevamp/profile.svg`,
  },
  {
    title: "Live Feedback",
    description:
      "Regular feedback sessions to ensure your satisfaction at every stage",
    image: `${process.env.CDN_URL}/assets/images/LSRevamp/feedback.svg`,
  },
]
export interface IconLink {
  text: string
  icon: string
  widgetName: string
}

export const iconLinksData: IconLink[] = [
  {
    text: "Crack IELTS",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/1.png`,
    widgetName: "Offerings - Crack IELTS",
  },
  {
    text: "Shortlist Colleges",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/2.png`,
    widgetName: "Offerings - Shortlist Colleges",
  },
  {
    text: "Financial Planning",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/3.png`,
    widgetName: "Offerings - Financial Planning",
  },
  {
    text: "SOP Review",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/4.png`,
    widgetName: "Offerings - SOP Review",
  },
  {
    text: "VISA Help",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/5.png`,
    widgetName: "Offerings - VISA Help",
  },
  {
    text: "Get a Coach",
    icon: `${process.env.CDN_URL}/assets/icons/LSRevamp/iconLinks/6.png`,
    widgetName: "Offerings - Get a Coach",
  },
]

export const studentTestimonialItems: Array<AboutUsBioCard> = [
  {
    img: `${process.env.CDN_URL}/assets/images/LSRevamp/student-testimonial-1.png`,
    name: "Vaishnavi",
    degree:
      "We almost missed the deadlines of University application, Leap Scholar helped us complete the process super fast",
    height: 437,
    width: 263,
    align: "start",
  },
  {
    img: `${process.env.CDN_URL}/assets/images/LSRevamp/student-testimonial-2.png`,
    name: "Parth",
    degree:
      "Joining Leapscholar was one of my best decision as it helped me with my IELTS exams preparation",
    height: 437,
    width: 263,
    align: "start",
  },
  {
    img: `${process.env.CDN_URL}/assets/images/LSRevamp/student-testimonial-3.png`,
    name: "Ishika",
    degree:
      "Leap’s expert counselors helped my daughter study in Warwick University",
    height: 437,
    width: 263,
    align: "start",
  },
  {
    img: `${process.env.CDN_URL}/assets/images/LSRevamp/student-testimonial-4.png`,
    name: "Ritwik",
    degree:
      "Atul Sir helped me score an IELTS band of 8 with just 4 weeks of crash course.",
    height: 437,
    width: 263,
    align: "start",
  },
]
export const commonAccordionData: AccordionData[] = [
  {
    title: "What is Leap Scholar?",
    content: `Leap Scholar (LS) is a one-stop platform for students who wish to pursue their studies abroad. We, at LS, assist students from the beginning of the application process to the time they set foot in the new country. We help students by taking care of profile evaluation, shortlisting of colleges, application submission to getting a students’ visa. We also offer guidance to ensure that every student who signs up with us successfully books a seat in their dream university. We follow a unique approach to counselling following which our team of experienced study-abroad consultants work with student mentors to help you make the right decision for your career.`,
  },
  {
    title: "Is Leap Scholar Free?",
    content: `Leap Scholar offers a bunch of services free-of-cost that includes preliminary consultations with a Leap counsellor, IELTS Masterclass as well as access to our events. However, we charge a nominal fee for our array of paid products that help you to fasttrack your journey towards your dream university.`,
  },
  {
    title: "What is the booking / service fee?",
    content: `If you’re applying for a Master’s program, you’ll need to pay a service fee of Rs.499 that includes all the benefits of Leap Scholar. However, this does not include the college application fee.`,
  },
  {
    title:
      "I want to pursue my undergraduate studies abroad. How can Leap scholar help me?",
    content: `Leap Advantage is the programme through which we help high school students receive quality education for almost half the cost. Most undergraduate courses abroad are expensive and, at times, unaffordable for many. With the Leap Advantage Twinning Program, you can begin your undergraduate studies in India, and, after completing two years, take a transfer to the US and graduate with an international degree. This not only helps you save up to 50% of the program fee but also gives you access to a plethora of internship and job opportunities in the US. We also offer a Course Credit Program to help you earn US college credits while in high school. Also, we provide you with scholarship assistance and opportunities using which you can ‘earn while you learn.’ Check out Leap Advantage for more information.`,
  },
  {
    title:
      "I have some more questions regarding IELTS Plus. How can I get them resolved?",
    content:
      "For queries about IELTS Plus, please feel free to write to us at contact@leapfinance.com",
  },
]
export const ieltsAccordionData: AccordionData[] = [
  {
    title: "What are the different batches available for IELTS preparation?",
    content:
      "You can choose from 3 batches: IELTS Focus, IELTS Prime and IELTS Boost",
  },
  {
    title: "Are these courses for IELTS Academic or General students?",
    content:
      "Our IELTS courses are suitable for both Academic test takers and General test takers.",
  },
  {
    title: "Are the programmes free of charge?",
    content: `The first class from our IELTS course is free. After that a fee will be charged based on the IELTS program you choose.`,
  },
  {
    title: "What is the difference among all the batches?",
    content:
      "The number of learning hours and tests varies based on the IELTS program you choose. IELTS Boost is a self-prep course where you can prepare at your own pace.",
  },
  {
    title: "What is the duration of Program?",
    content:
      "IELTS Focus has 20 hours of live classes while IELTS Prime has 30 hours of live classes. Both these programs have daily free grammar and doubt solving classes that you can join.",
  },
  {
    title: "What are the class timings?",
    content:
      "We have multiple batches at various timings, you can choose a batch that fits your schedule.",
  },
  {
    title: "Will all programmes have live doubt solving sessions?",
    content:
      "Yes, all our programmes have dedicated doubt-solving sessions held daily.",
  },
]
